import './main.css';

import { Button, Grid, Input, List, Tag } from 'antd';
import { debounce } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { BannerWrapper } from '../components/BannerWrapper';
import { CustomToolRequestCard } from '../components/CustomToolRequestCard';
import { FavTagButton } from '../components/FavTagButton';
import { ToolCard } from '../components/ToolCard';
import { useAuth } from '../providers/AuthProvider';
import { toolBoxStore } from '../store/store';
import { sendYmEvent } from '../utils/metrics';
import { normalizeStringForSearch } from '../utils/misc';

import type { ToolInfo } from '../store/store';
import type { Breakpoint } from 'antd';
import type { ReactNode } from 'react';

const { useBreakpoint } = Grid;

const getFilteredTools = (
  tools: ToolInfo[],
  selectedTagIds: number[],
  keyword: string,
  favoriteSelected?: boolean,
) => {
  if (keyword === '' && selectedTagIds.length === 0 && !favoriteSelected) {
    return tools;
  }

  const normalizedKeyword = normalizeStringForSearch(keyword);

  const filteredTools = tools.filter((t) => {
    if (favoriteSelected && !t.is_favorite) {
      return false;
    }

    const text = [
      String(t.name),
      String(t.description),
      String(t.operation_id),
    ].join('');

    const normalizedtext = normalizeStringForSearch(text);

    if (selectedTagIds.length === 0) {
      return !normalizedKeyword || normalizedtext.includes(normalizedKeyword);
    } else {
      return (
        t.tags.length > 0 &&
        selectedTagIds.every((tagId) => t.tags.includes(tagId)) &&
        (!normalizedKeyword || normalizedtext.includes(normalizedKeyword))
      );
    }
  });

  return filteredTools;
};

// temp?
const getSearchInputWidth = (screens: Partial<Record<Breakpoint, boolean>>) => {
  const { sm, md, lg, xxl } = screens;
  if (sm && !md) return '100%';
  if (sm && md && !lg) return 'calc(50% - 8px)';
  if (sm && md && lg && !xxl) return 'calc(33% - 10px)';
  if (xxl) return 'calc(25% - 12px)';
};

/////////////////////////////////////////////////////////////////////////////////

const Main: React.FC = observer(() => {
  const { t } = useTranslation();
  const auth = useAuth();

  const tags = toolBoxStore.tagsList;

  const { CheckableTag } = Tag;
  const [searchParams, setSearchParams] = useSearchParams();

  const getTagsFromUrl = () =>
    searchParams.get('tags')?.split(',').map(Number) || [];

  const [selectedTagIds, setSelectedTagIds] =
    useState<number[]>(getTagsFromUrl());

  const [favoriteSelected, setFavoriteSelected] = useState<boolean>(
    searchParams.has('favorite'),
  );

  const [keyword, setKeyword] = useState<string>(
    searchParams.get('search') || '',
  );
  const [searchInputValue, setSearchInputValue] = useState<string>(
    searchParams.get('search') || '',
  );

  const screens = useBreakpoint();

  const onSearchInput = debounce((keyword: string) => {
    setSearchParams((params) => {
      if (keyword.length > 0) {
        params.set('search', keyword);
      } else {
        params.delete('search');
      }
      return params;
    });
    setKeyword(keyword);
  }, 20);

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    setSearchInputValue(value);
    onSearchInput(value);
  };

  const handleTagsChange = (tagId: number, checked: boolean) => {
    sendYmEvent('tagsUsed');

    const nextSelectedTags = checked
      ? [...selectedTagIds, tagId]
      : selectedTagIds.filter((t) => t !== tagId);
    setSelectedTagIds(nextSelectedTags);

    setSearchParams((params) => {
      if (nextSelectedTags.length > 0) {
        params.set('tags', nextSelectedTags.join(','));
      } else {
        params.delete('tags');
      }
      return params;
    });
  };

  const handleFavChange = (checked: boolean) => {
    setFavoriteSelected(checked);
    if (checked) {
      setSearchParams((params) => {
        params.append('favorite', 'true');
        return params;
      });
    } else {
      setSearchParams((params) => {
        params.delete('favorite');
        return params;
      });
    }
  };

  const handleResetQuery = () => {
    setSelectedTagIds([]);
    setSearchInputValue('');
    setKeyword('');
    setSearchParams((params) => {
      params.delete('search');
      params.delete('tags');
      params.delete('favorite');
      return params;
    });
  };

  useEffect(() => {
    const getTagsFromUrl = () =>
      searchParams.get('tags')?.split(',').map(Number) || [];
    setSelectedTagIds(getTagsFromUrl);

    const paramsKeyword = searchParams.get('search') || '';
    setKeyword(paramsKeyword);
    setSearchInputValue(paramsKeyword);

    setFavoriteSelected(searchParams.has('favorite'));
  }, [searchParams]);

  // Kappa
  type ListItemType = 'special' | 'tool';

  type MainListItem = {
    type: ListItemType;
    content: ReactNode;
  };

  const specialCardData = {
    type: 'special' as ListItemType,
    content: <CustomToolRequestCard />,
  };

  const filteredTools = getFilteredTools(
    toolBoxStore.tools,
    selectedTagIds,
    keyword,
    favoriteSelected,
  ).map((tool) => ({
    type: 'tool' as ListItemType,
    content: <ToolCard tool={tool} />,
  }));

  const combinedListData: MainListItem[] = [...filteredTools];

  if (
    selectedTagIds.length === 0 &&
    !keyword &&
    !favoriteSelected &&
    toolBoxStore.deployMode === 'cloud'
  ) {
    // Lead to Google Form hidden in en for AI chat
    combinedListData.unshift(specialCardData);
  }

  return (
    <>
      <Helmet>
        <meta property="og:title" content={t('og.main.title')} />
        <meta property="og:description" content={t('og.main.description')} />
        {/* <meta property="og:image" content="Your image URL" /> */}
      </Helmet>
      <div
        style={{
          // height: 'calc(100vh - 52px)',
          // height:
          //   toolBoxStore.deployMode === 'onpremise'
          //     ? 'calc(100vh - 52px)'
          //     : 'calc(100vh - 36px - 176px)',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {toolBoxStore.deployMode === 'cloud' && <BannerWrapper />}
        {/* search */}
        <div
          style={{
            paddingBottom: '12px',
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection:
              (screens.sm && !screens.lg) || screens.xs ? 'column' : 'row',
            gap: '8px',
            boxShadow: '0px 4px 4px rgb(0, 0, 0, 0.05)',
            width: '100%',
            padding: '16px 16px 10px 16px',
            zIndex: 101,
            backgroundColor: 'rgb(245, 245, 245',
            position: 'relative',
          }}
        >
          <Input
            autoFocus
            allowClear
            style={{
              flexShrink: 0,
              alignSelf: 'center',
              transitionDuration: '0s',
              width: getSearchInputWidth(screens),
            }}
            value={searchInputValue}
            onChange={handleSearchInputChange}
            placeholder={t('main.search')}
          />

          {/* wtf is this lol */}
          <div
            style={{
              alignSelf: 'center',
              lineHeight: 2,
            }}
          />

          <div
            style={{
              display: 'flex',
              gap: '6px 0',
              flexWrap: 'wrap',
              marginRight: 'auto',
            }}
          >
            {auth.isLoggedIn() && (
              <FavTagButton
                favoriteSelected={favoriteSelected}
                handleFavChange={handleFavChange}
              />
            )}
            {tags.map((item) => (
              <CheckableTag
                style={{ alignSelf: 'center' }}
                key={item.id}
                checked={selectedTagIds.includes(item.id)}
                onChange={(checked) => handleTagsChange(item.id, checked)}
              >
                <span>{item.name}</span>
              </CheckableTag>
            ))}
          </div>

          <Button
            type="text"
            style={{
              alignSelf:
                (screens.sm && !screens.lg) || screens.xs
                  ? 'flex-end'
                  : 'center',
            }}
            onClick={handleResetQuery}
          >
            {t('main.tags.reset')}
          </Button>
        </div>
        <div
          style={{
            zIndex: 100,
            overflowY: 'hidden',
            height: '100%',
            display: screens.sm && screens.md ? 'flex' : 'block',
          }}
        >
          <div
            style={{
              zIndex: 100,
              // overflowY: 'scroll',
              height: '100%',
              width: '100%',
            }}
          >
            <List
              style={{
                backgroundColor: '#f5f5f5',
                padding: '16px 16px 36px 16px',
                zIndex: 100,
              }}
              grid={{
                gutter: 16,
                xs: 1,
                sm: 1,
                md: 2,
                lg: 3,
                xl: 3,
                xxl: 4,
              }}
              dataSource={combinedListData}
              renderItem={(item) => (
                <List.Item
                  style={{
                    height: '100%',
                  }}
                >
                  {item.content}
                </List.Item>
              )}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          position: 'fixed',
          bottom: 0,
          height: '100%',
          width: '100%',
          zIndex: 0,
          backgroundColor: '#f5f5f5',
        }}
      ></div>
    </>
  );
});

export { Main };
